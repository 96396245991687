@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700&display=swap');

body{
    background: #F5F5F5;
    font-family: 'Raleway', sans-serif;
}

/* Toast Message  */
.ct-flex-bottom{
    margin-bottom: 60px !important;
}
.ct-text{
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    color: #293C5B;
    font-weight: 600;
}

/* Loading Overlay */
.LoadingOverlay {
    display: block;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 500;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
}
.center-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 90vh;
}

.loader-icon{
    width: 170px;
    height: 170px;
}

/* Top Navbar */
.navbar{
    box-shadow: 0 2px 2px rgba(0,0,0,.03);
    background-color: white !important;
}
.nav-logo{
    height: 63px;
    width: auto;
}


/* Side Bar & Content */
.content {
    padding: 10px;
    margin-right: 20%;
}
.side-nav {
    height: 100%;
    width: 20%;
    position: fixed;
    top: 0;
    right: 0;
    padding: 100px 10px 20px 10px;
    overflow-x: hidden;
    background-color: #ffffff;
    box-shadow: 0 2px 2px 2px rgba(0,0,0,.03);
}


/* Form  */
.form-label {
    color: #293C5B;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 500;
}
.form-control{
    padding: 9px;
    border-radius: 3px;
    color: #293C5B;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    background-color: #E9ECF1;
    font-weight: 400 !important;
    border: 1px solid #E9ECF1;
}
.form-control:focus {
    transition: 0.3ms;
    box-shadow: none !important;
    padding: 9px;
    color: #293C5B !important;
    font-size: 14px;
    background-color: #E9ECF1;
    border: 1px solid #E9ECF1;
}


/* Button  */
.btn {
    padding: 8px;
    outline: none !important;
    border-radius: 3px ;
    cursor: pointer !important;
    font-weight: 600;
    font-family: 'Raleway', sans-serif;
    -webkit-tap-highlight-color: transparent;
    box-shadow: none;
}
.btn:focus {
    outline: none !important;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.16), 0 4px 10px 0 rgba(0, 0, 0, 0.12);
}
.btn:active {
    outline: none !important;
    box-shadow: none !important;
}
.btn:hover {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16), 0 4px 10px 0 rgba(0, 0, 0, 0.12);
    cursor: pointer !important;
}
.btn-danger {
    border: none !important;
    background: rgb(205,47,47);
    background: linear-gradient(90deg, rgba(205,47,47,1) 0%, rgba(146,6,6,1) 72%);
}
.btn-success {
    border: none !important;
    background: #61AA43;
}
.img-remove-btn{
    margin: auto;
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
}


/* Image Object Fit */
.Image-Top{
    width: 100%;
    height: auto;
    object-fit: contain;
}
.Image-Bottom{
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
    position:absolute;
    object-fit: contain;
}
.Image-Center{
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: auto;
    object-fit: contain;
}
.Image-Stretch{
    width: 100%;
    height: 100%;

}
.Image-Cover{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* Page Size */
.page-container{
    height:auto;
    width: auto;
    background: #E4E4E4;
    padding: 10px;
}


.A4{
    margin: auto;
    position: relative;
    width: 198px;
    height: calc(1.4151 * 198px) ;
    border: none;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    background-color: white !important;
}
.LETTER{
    margin: auto;
    position: relative;
    width: 198px;
    height: calc(1.29 * 198px) ;
    border: none;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    background-color: white !important;
}
.LEGAL{
    margin: auto;
    position: relative;
    width: 198px;
    height: calc(1.647 * 198px) ;
    border: none;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    background-color: white !important;
}
.TABLOID{
    margin: auto;
    position: relative;
    width: 198px;
    height: calc(1.545 * 198px) ;
    border: none;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    background-color: white !important;
}
.EXECUTIVE{
    margin: auto;
    position: relative;
    width: 198px;
    height: calc(1.449 * 198px) ;
    border: none;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    background-color: white !important;
}


/* Page Margin */
.Normal-Margin{
 padding: 24px !important;
}
.Narrow-Margin{
    padding: 12px !important;;
}
.Moderate-Margin{
    padding: 18px !important;
}


