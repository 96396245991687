@media (max-width: 339.98px) {

    .nav-logo{
        height: 35px;
        width: auto;
    }
    .banner-title{
        font-size: 28px;
        font-weight: 700;
    }
    .banner-sub-title{
        font-size: 13px;
        font-weight: 400;
    }
    .login-form{
        padding: 30px 30px 100px 30px;
    }
    .reg-form{
        padding: 20px 30px 100px 30px;
    }

    .form-title{
        font-size: 24px;
    }



}

@media (min-width:340px) and (max-width: 360.98px) {

    .nav-logo{
        height: 35px;
        width: auto;
    }
    .banner-title{
        font-size: 28px;
        font-weight: 700;
    }
    .banner-sub-title{
        font-size: 14px;
        font-weight: 400;
    }
    .login-form{
        padding: 30px 30px 100px 30px;
    }
    .reg-form{
        padding: 20px 30px 100px 30px;
    }
    .form-title{
        font-size: 24px;
    }
}

@media (min-width: 361px) and (max-width: 575.98px) {
    .nav-logo{
        height: 35px;
        width: auto;
    }
    .banner-title{
        font-size: 32px;
        font-weight: 700;
    }
    .banner-sub-title{
        font-size: 15px;
        font-weight: 500;
    }
    .login-form{
        padding: 30px 30px 100px 30px;
    }
    .reg-form{
        padding: 20px 30px 100px 30px;
    }
    .form-title{
        font-size: 24px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .nav-logo{
        height: 35px;
        width: auto;
    }
    .banner-title{
        font-size: 36px;
        font-weight: 700;
    }
    .banner-sub-title{
        font-size: 18px;
        font-weight: 500;
    }
    .login-form{
        padding: 30px 30px 100px 30px;
    }
    .reg-form{
        padding: 20px 30px 100px 30px;
    }
    .form-title{
        font-size: 24px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .nav-logo{
        height: 35px;
        width: auto;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {


}

@media (min-width: 1200px) {



    
}
